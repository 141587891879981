import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['name', 'email', 'postalCode', 'submit', 'submitText']

  connect () {
    this.updateSubmit()
  }

  updateSubmit () {
    if (this.validate()) {
      this.submitTarget.disabled = false
      this.submitTarget.classList.remove('opacity-50')
      this.submitTextTarget.innerText = this.submitTextTarget.dataset.enabledPlaceholder
    } else {
      this.submitTarget.disabled = true
      this.submitTarget.classList.add('opacity-50')
      this.submitTextTarget.innerText = this.submitTextTarget.dataset.disabledPlaceholder
    }
  }

  validate () {
    return (
      this.nameTarget.value.trim().length > 0 &&
      this.emailTarget.value.trim().length > 0 &&
      this.postalCodeTarget.value.trim().length > 0
    )
  }
}
