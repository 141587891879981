import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['disclosure', 'interestedEdu', 'submit']

  connect () {
    this.hide();
    this.submitTarget.parentNode.classList.add('hidden');
  }

  selectCheckBox (event) {
    var index;
    var selectedInput;
    var selectedButton;
    var element = event.target;
    const inputs = document.querySelectorAll("input[type=checkbox]");
    for (index = 0; index < inputs.length; ++index) {
      inputs[index].checked = false;
    }

    if (element.type == 'checkbox'){
      selectedInput = element;
      selectedButton = element.parentNode;
    } else {
      selectedInput = element.querySelector('input');
      selectedButton = element;
    }

    selectedInput.checked = true;
    if (selectedButton.value == 'true'){
      this.show();
    } else {
      this.hide();
    }
    this.handleSubmitButton(selectedInput);
  }

  show() {
    this.disclosureTarget.classList.remove('hidden');
    this.submitTarget.innerHTML = 'Agree and Continue';
  }

  hide() {
    this.disclosureTarget.classList.add('hidden');
    this.submitTarget.innerHTML = 'Continue';
  }

  handleSubmitButton(selectedInput) {
    const button = this.submitTarget.parentNode;
    if (selectedInput.checked == true){
      button.type = 'submit';
      button.classList.remove('hidden');
    }
    else{
      button.type = 'button';
      button.classList.add('hidden');
    }
  }

  everflow(event) {
    var element = event.target;
    var eventId = element.dataset.eventId;
    var jobCompany = element.dataset.jobCompany;
    var jobCompanyDomain = element.dataset.jobCompanyDomain;
    EF.conversion({
      offer_id: Cookies.get('oid'),
      affiliate_id: Cookies.get('affid'),
      sub1: Cookies.get('sub1'),
      sub2: Cookies.get('sub2'),
      sub3: Cookies.get('sub3'),
      sub4: Cookies.get('sub4'),
      sub5: Cookies.get('sub5'),
      source_id: Cookies.get('source_id'),
      event_id: eventId,
      adv1: jobCompany,
      adv2: jobCompanyDomain,
      amount: '0.25'
     });
    
    this.submitTarget.parentNode.setAttribute('disabled', 'disabled');
    this.submitTarget.textContent = 'Submitting..';
  }

  checkForError(event) {

    const checked = document.querySelectorAll("input:checked").length
    if (checked > 0){
      this.submitTarget.parentNode.classList.remove('hidden');
    }
    else{
      this.submitTarget.parentNode.classList.add('hidden');
    }
  }
}
