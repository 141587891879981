// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import '@hotwired/turbo-rails'
//import * as ActiveStorage from '@rails/activestorage'
import 'channels'
require.context('../images', true)

Rails.start()
//ActiveStorage.start()

import '@fortawesome/fontawesome-free/webfonts/fa-regular-400.eot'
import '@fortawesome/fontawesome-free/webfonts/fa-regular-400.svg'
import '@fortawesome/fontawesome-free/webfonts/fa-regular-400.ttf'
import '@fortawesome/fontawesome-free/webfonts/fa-regular-400.woff'
import '@fortawesome/fontawesome-free/webfonts/fa-regular-400.woff2'
import '@fortawesome/fontawesome-free/webfonts/fa-solid-900.eot'
import '@fortawesome/fontawesome-free/webfonts/fa-solid-900.svg'
import '@fortawesome/fontawesome-free/webfonts/fa-solid-900.ttf'
import '@fortawesome/fontawesome-free/webfonts/fa-solid-900.woff'
import '@fortawesome/fontawesome-free/webfonts/fa-solid-900.woff2'
import '../stylesheets/main.scss'
import 'controllers'
import debounced from 'debounced'
debounced.initialize()
