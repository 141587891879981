import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['location', 'postalCode', 'edit', 'submit', 'cancel']

  connect () {
    this.cancelEdit()
  }

  showEdit (event) {
    if (event) event.preventDefault()
    this.locationTarget.classList.add('hidden')
    this.editTarget.classList.add('hidden')
    this.postalCodeTarget.classList.remove('hidden')
    this.submitTarget.classList.remove('hidden')
    this.cancelTarget.classList.remove('hidden')
    this.postalCodeTarget.select()
    this.postalCodeTarget.focus()
  }

  cancelEdit (event) {
    if (event) event.preventDefault()
    this.locationTarget.classList.remove('hidden')
    this.editTarget.classList.remove('hidden')
    this.postalCodeTarget.classList.add('hidden')
    this.submitTarget.classList.add('hidden')
    this.cancelTarget.classList.add('hidden')
  }
}
