import * as Turbo from '@hotwired/turbo'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['activity', 'content']
  static values = {
    clickBody: String,
    debug: Boolean,
    destinationUrl: String,
    displayed: Boolean,
    eventUrl: String,
    external: Boolean,
    impressionBody: String,
    nextUrl: String
  }

  connect () {
    if (this.content.length > 0) {
      this.trackImpression()
    } else {
      this.showActivity()
      if (this.nextUrlValue === this.destinationUrlValue) {
        window.location = this.nextUrlValue
      } else {
        Turbo.visit(this.nextUrlValue)
      }
    }
  }

  showActivity () {
    this.activityTarget.hidden = false
    this.contentTarget.hidden = true
  }

  showContent () {
    this.activityTarget.hidden = true
    this.contentTarget.hidden = false
  }

  get content () {
    return this.contentTarget.innerHTML.trim()
  }

  trackImpression () {
    if (this.displayedValue) return
    if (this.content.length === 0) return
    this.trackEvent(this.eventUrlValue, this.impressionBodyValue)
  }

  trackClick (event) {
    if (!this.externalValue) return
    if (this.content.length === 0) return
    this.trackEvent(this.eventUrlValue, this.clickBodyValue)
  }

  trackEvent (url, body) {
    if (this.debugValue) console.log('recommendation#trackEvent', url, body)
    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: body
    })
  }
}
