import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['disclosure', 'interestedEdu', 'submit', 'submitText']

  connect () {
  }

  everflow(event) {
    var element = event.target;
    var eventId = element.dataset.eventId;
    var jobCompany = element.dataset.jobCompany;
    var jobCompanyDomain = element.dataset.jobCompanyDomain;
    EF.conversion({
      offer_id: Cookies.get('oid'),
      affiliate_id: Cookies.get('affid'),
      sub1: Cookies.get('sub1'),
      sub2: Cookies.get('sub2'),
      sub3: Cookies.get('sub3'),
      sub4: Cookies.get('sub4'),
      sub5: Cookies.get('sub5'),
      source_id: Cookies.get('source_id'),
      event_id: eventId,
      adv1: jobCompany,
      adv2: jobCompanyDomain,
      amount: '0.04'
    });
    this.submitTarget.setAttribute('disabled', 'disabled');
    this.submitTextTarget.textContent = 'Submitting..';
  }
}
