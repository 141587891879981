import { Controller } from 'stimulus'

// Google identifier prefixes and what they mean
// https://www.thyngster.com/technical-guide-to-global-site-tag-gtag-js
//
// AW-* = Adwords, Conversion ID
// DC-* = Double Click, Advertiser ID
// UA-* = Universal Analytics, Property ID
// G-* = App+Web, Measurement ID
//
// GA4 Google Recommended Events
// https://support.google.com/analytics/topic/9756175?hl=en&ref_topic=9143232
//
// MatrixMedia Canonical Events
//
//  * visit - landing page visit
//    - google: n/a collected by default https://developers.google.com/analytics/devguides/collection/ga4/tag-guide#page_views_and_screen_views
//
//  * lead - creation of a valid lead
//    - google: generate_lead (value, currency)
//
//  * impression - offer/recommendation presented to the visitor
//    - google: view_item (currency, items, value)
//
//  * click - offer/recommendation clicked by the visitor
//    - google: purchase (affiliation, coupon, currency, items, transaction_id, shipping, tax, value)
//
//  * coregistration - offer/recommendation clicked by the visitor
//    - google: purchase (affiliation, coupon, currency, items, transaction_id, shipping, tax, value)
//

export default class extends Controller {
  static values = {
    company: String,
    debug: Boolean,
    leadAge: Number,
    leadStep: String,
    recommendationId: String,
    recommendationOfferDisplayPosition: Number,
    recommendationOfferType: String,
    showNextRecommendation: Boolean,
    total: Number
  }

  connect () {
    if (this.debugValue) console.log('pixels controller connected')
    this.trackImpression()
    this.trackLeadCapture()
  }

  trackLeadCapture () {
    if (this.leadStepValue !== 'add_info') return
    if (this.leadAgeValue > 3) return

    // FACEBOOK
    this.exec(() => {
      window.fbq('track', 'Lead')
      if (this.debugValue)
        console.log('pixels.trackLeadCapture', 'facebook lead')
    })

    // GOOGLE
    this.exec(() => {
      const value = 0.12 // hard coded heuristic until we get server side tracking
      const args = {
        value,
        currency: 'USD',
        affiliation: 'lead',
        category: 'Monetization',
        action: 'LeadCreated',
        label: this.recommendationOfferType
      }
      window.gtag('event', 'generate_lead', args)
      if (this.debugValue)
        console.log(
          'pixels.trackLeadCapture',
          'google analytics generate_lead',
          args
        )
      if (window.mmAdwords)
        window.mmAdwords.trackLeadCapture(this.debugValue, args)
      if (window.mmAnalytics)
        window.mmAnalytics.trackLeadCapture(this.debugValue, args)
    })
  }

  trackImpression (event) {
    // FACEBOOK
    this.exec(() => {
      window.fbq('track', 'PageView')
      if (this.debugValue)
        console.log('pixels.trackImpression', 'facebook page view')
    })

    // GOOGLE
    this.exec(() => {
      if (this.recommendationIdValue.length > 0) {
        const args = {
          value: this.totalValue,
          currency: 'USD',
          category: 'Impression',
          action: 'OfferPresented',
          label: this.recommendationOfferType
        }
        window.gtag('event', 'view_item', args)
        if (this.debugValue)
          console.log(
            'pixels.trackImpression',
            'google analytics view_item',
            args
          )
      }
    })
  }

  trackClick (event) {
    // FACEBOOK
    this.exec(() => {
      window.fbq('track', 'Purchase', {
        value: this.totalValue,
        currency: 'USD',
        content_category: new Date().getTime() + '_regular_pixel',
        content_name: this.companyValue
      })
      if (this.debugValue)
        console.log(
          'pixels.trackClick',
          'facebook purchase',
          this.totalValue,
          this.companyValue
        )
    })

    // GOOGLE
    this.exec(() => {
      const args = {
        affiliation: 'offer',
        value: this.totalValue,
        currency: 'USD',
        category: 'Monetization',
        action: 'OfferClicked',
        label: this.recommendationOfferType
      }
      gtag('event', 'purchase', args)
      if (this.debugValue)
        console.log('pixels.trackClick', 'google analytics purchase', args)
      if (window.mmAdwords) window.mmAdwords.trackClick(this.debugValue, args)
    })

    const trigger = event.target.closest(
      '[data-action*="click->pixels#trackClick"]'
    )
    if (trigger) trigger.removeAttribute('data-action')

    fetch(`/recommendation/${this.recommendationIdValue}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ clicked: true })
    }).then(this.advance.bind(this))
  }

  exec (callback) {
    try {
      callback.call(this)
    } catch (e) {
      if (this.debugValue)
        console.error(
          'Error executing guarded logic in the pixels controller!',
          e
        )
    }
  }

  advance () {
    if (this.debugValue) return
    if (this.showNextRecommendationValue && this.nextRecommendationAnchor)
      this.nextRecommendationAnchor.click()
  }

  get nextRecommendationAnchor () {
    return document.getElementById('next-recommendation')
  }
}
