import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['disclosure', 'interestedEdu', 'submit', 'submitText']

  connect () {
    EF.impression({
      offer_id: Cookies.get('oid'),
      affiliate_id: Cookies.get('affid'),
      sub1: Cookies.get('sub1'),
      sub2: Cookies.get('sub2'),
      sub3: Cookies.get('sub3'),
      sub4: Cookies.get('sub4'),
      sub5: Cookies.get('sub5'),
      source_id: Cookies.get('source_id'),
      uid: Cookies.get('uid'),
      creative_id: Cookies.get('creative_id'),
      fbclid: Cookies.get('fbclid'),
      gclid: Cookies.get('gclid'),
    });
    EF.click({
      offer_id: Cookies.get('oid'),
      affiliate_id: Cookies.get('affid'),
      sub1: Cookies.get('sub1'),
      sub2: Cookies.get('sub2'),
      sub3: Cookies.get('sub3'),
      sub4: Cookies.get('sub4'),
      sub5: Cookies.get('sub5'),
      source_id: Cookies.get('source_id'),
      uid: Cookies.get('uid'),
      creative_id: Cookies.get('creative_id'),
      fbclid: Cookies.get('fbclid'),
      gclid: Cookies.get('gclid'),
    });
  }

  everflow(event) {
    this.submitTarget.setAttribute('disabled', 'disabled');
    this.submitTextTarget.textContent = 'Submitting..';
  }
}
