import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['accept', 'submit', 'agree']

  connect () {
    if (this.acceptTarget.checked == true)
      this.submitTarget.type = 'submit'
  }

  showAccept (event) {
    if (this.acceptTarget.checked == true)
      this.show()
    else
      this.hide()
  }

  checkAccept () {
    if (this.acceptTarget.checked == false){
      this.agreeTarget.classList.add('error')
      this.submitTarget.type = 'button'
    } else {
      this.submitTarget.type = 'submit'
    }
  }

  changeAccept (event) {
    var element = event.target;
    if (element.type == 'checkbox'){
      this.showAccept();
    } else {
      if (this.acceptTarget.checked == false){
        this.acceptTarget.checked = true;
        this.show();
      } else {
        this.acceptTarget.checked = false;
        this.hide();
      }
    }
  }

  everflow(event) {
    var element = event.target;
    this.submitTarget.setAttribute('disabled', 'disabled');
    this.submitTarget.value = 'Please wait...';
    var eventId = element.dataset.eventId;
    var jobCompany = element.dataset.jobCompany;
    var jobCompanyDomain = element.dataset.jobCompanyDomain;
    EF.conversion({
      offer_id: Cookies.get('oid'),
      affiliate_id: Cookies.get('affid'),
      sub1: Cookies.get('sub1'),
      sub2: Cookies.get('sub2'),
      sub3: Cookies.get('sub3'),
      sub4: Cookies.get('sub4'),
      sub5: Cookies.get('sub5'),
      event_id: eventId,
      adv1: jobCompany,
      adv2: jobCompanyDomain,
      amount: ''
     });
  }

  show() {
    this.agreeTarget.classList.remove('error')
    this.submitTarget.type = 'submit'
  }

  hide() {
    this.submitTarget.type = 'button'
  }
}
